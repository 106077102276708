import mushroom from './mushroom.file';

var NProgress = require('nprogress');
NProgress.configure({
  trickleSpeed: 1000,
  minimum: 0.2,
});

function blockUi() {
  NProgress.start();
  $("body").css("pointer-events", "none");
}

function unblockUi() {
  NProgress.done();
  Ladda.stopAll();
  $("body").css("pointer-events", "auto");
}

window.requestCount = 0;

let ExtensionService = {};

ExtensionService.urlImg = window.MyConfig.hostApi + "file/thumb?id=";
ExtensionService.urlFile = window.MyConfig.hostApi + "file/download?id=";


ExtensionService.upload = (rawDataFile, tags) => {
  blockUi();
  return mushroom.$file.uploadAsync(
    {
      file: rawDataFile.raw, // required
      name: rawDataFile.name, // optional
      tags: tags
    }
  ).then(response => {
    unblockUi();
    return response;
  })
    .catch(error => {
      unblockUi();
      toastr.error(LanguageService.lang.c_uploadError);
      throw error;
    });
};

export default ExtensionService;
