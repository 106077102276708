import mushroom from 'mushroomjs';

mushroom.$file = mushroom.$file || {};

mushroom.$file.uploadAsync = (args) => 
    mushroom.__createAsyncRestFunction({
        name: "$file.uploadAsync",
        method: "POST",
        blankBody: false,
        isRawData: true,
        url: mushroom._fnGetRootApiUrl("fileUpload") + "file/upload",
        headers: {
            FileName: encodeURIComponent(args.name || args.file.name),
            Tags: args.tags,
            "Content-Format": args.contentFormat
        },
        events: {
            progress: args.progress
        }
    })(args.file);

mushroom.$file.getDownloadLink = (id) => mushroom._fnGetRootApiUrl("fileDownload") + "file/download?id=" + id;
mushroom.$file.getZipLink = (ids) => {
    if (!ids || !Array.isArray(ids) || !ids.length)
        throw new TypeError("Require list of file id to zip");

    return mushroom._fnGetRootApiUrl("fileDownload") + "file/download?id=" + ids.join(",");
};

function download(url) {
    var downloadLink = document.createElement("a");
    downloadLink.href = url;
    //downloadLink.innerHTML = "Download File";
    // Firefox requires the link to be added to the DOM
    // before it can be clicked.
    downloadLink.onclick = function () {
        document.body.removeChild(downloadLink);
    };
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);

    downloadLink.click();
}

mushroom.$file.download = (id) => download(mushroom.$file.getDownloadLink(id));

mushroom.$file.zip = (ids) => download(mushroom.$file.getZipLink(ids));

export default mushroom;